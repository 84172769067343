<template>
    <a-modal v-model:visible="visible" :title="title" width="468px" :footer="false">
        <a-list :loading="loading" item-layout="horizontal" :data-source="data">
            <template #renderItem="{ item }">
                <a-list-item>
                    <a-list-item-meta>
                        <template #description>
                            <span v-html="item.description" @click="clickDetail" />
                        </template>
                        <template #title>
                            <div style="display:flex;justify-content:space-between">
                                <span>{{ item.name }}</span>
                                <span>{{ item.time }}</span>
                            </div>
                        </template>
                        <template #avatar>
                            <a-avatar :src="item.avatar" />
                        </template>
                    </a-list-item-meta>
                </a-list-item>
            </template>
        </a-list>
    </a-modal>
    <a-modal :visible="image_modal" :footer="null" @cancel="image_modal = false" :closable="false">
        <img alt="图片加载失败，可能是文件已被清除。" style="width: 100%" :src="image_src" />
    </a-modal>
</template>

<script>
/**
 * 使用方法
 * 为组建添加refs属性，如refs="DataLog"
 * 并为其传入data_table属性，值为数据表名
 * 在需要启动出调用$refs.DataLog.show()方法，参数见下方函数
 */
export default {
    name: 'DataLog',
    props: {
        data_table: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            // 控制弹窗显示有
            visible: false,
            // 打开时加载
            loading: true,
            // 表、字段的翻译
            table_name: {
                supplier: '供应商',
                storage: '储存区',
                category: '物料分类',
                material: '物料管理',
            },
            field_name: {
                supplier: {
                    name: '名称',
                    address: '地址',
                    images: '图片',
                },
                storage: {
                    name: '储存区名称',
                    description: '储存区描述',
                    parent_id: '父级储存区',
                },
                category: {
                    name: '物料分类名称',
                    description: '分类描述',
                    parent_id: '父级分类',
                    prefix: '分类前缀',
                },
                material: {
                    name: '物料名称',
                    unit: '计量单位',
                    category_id: '所属分类',
                    prefix: '前缀',
                    suffix: '后缀',
                    texture: '材质',
                    specification: '规格',
                    remark: '备注',
                    tags: '标签',
                    images: '图片',
                },
            },
            // 弹窗标题
            title: '数据日志',
            // 弹窗内容
            data: [],
            // 控制图片弹窗
            image_modal: false,
            image_src: '',
        };
    },
    methods: {
        show: function(data_id, data_table = this.data_table) {
            this.visible = true;
            if (this.table_name[data_table] != undefined) {
                this.getData(data_id, data_table);
            } else {
                // 记得向该组件的data中添加相应数据
                console.error('调用DataLogModal时没有传入数据表名');
            }
        },
        async getData(data_id, data_table) {
            this.loading = true;
            this.data = [];
            let res = this.$api('getDataLog', { data_table, data_id }).then((res) => {
                this.title = `数据日志 【${this.table_name[this.data_table]} - ${res.title}】`;
                this.parseData(res.data_log);
            });
        },
        async parseData(data) {
            let res = [];
            for (let i = 0; i < data.length; i++) {
                let user_info = await this.$api('getUserInfo', { user_id: data[i].user_id });
                let temp = {
                    user_id: data[i].user_id,
                    name: user_info.name,
                    avatar: user_info.avatar,
                    time: data[i].time,
                    description: await this.parseDesc(data[i].operation, data[i].detail),
                };
                res.push(temp);
            }
            this.data = res;
            this.loading = false;
        },
        /**
         * 对详情进行解析
         * 生成后的描述将使用v-html渲染，能够体现点击事件
         * 点击图片可以打开图片预览框
         */
        async parseDesc(operation, detail) {
            detail = JSON.parse(detail);
            // 四个用于拼接字符串的快捷函数，使用span、yellow、red、green、blue的单词首字母
            let s_y = (str) => {
                return `<span style="color:#E6A23C">${str}</span>`;
            };
            let s_r = (str) => {
                return `<span style="color:#F56C6C">${str}</span>`;
            };
            let s_g = (str) => {
                return `<span style="color:#67C23A">${str}</span>`;
            };
            let s_b = (str) => {
                return `<span style="color:#1890FF">${str}</span>`;
            };
            // 操作为添加，detail:{datas:[someting,something],fields:[somefield,somefield]}
            if (operation == 'add') {
                let temp = '提交了数据。</br>';
                for (let i = 0; i < detail.fields.length; i++) {
                    let table = this.field_name[this.data_table];
                    if (table == undefined) throw new Error('搞什么锤子？赶紧把表里的字段添加到data.field_name里');
                    let field = table[detail.fields[i]];
                    switch (field) {
                        case undefined:
                            break;
                        case '图片':
                            detail.datas[i] = JSON.parse(detail.datas[i]);
                            // 进行拼接，格式：新增图片：图片，图片，图片；
                            detail.datas[i].length > 0 ? (temp = `${temp}${s_g('新增')}${s_y('图片')}：`) : '';
                            detail.datas[i].forEach((item) => {
                                temp = temp + `<a type="img" src="${item}">图片</a>，`;
                            });
                            detail.datas[i].length > 0 ? (temp = `${temp.slice(0, -1)}；`) : '';
                            break;
                        case '标签':
                            // 进行拼接，格式：新增标签：xx，xxxx；
                            detail.datas[i].length > 0 ? (temp = `${temp}${s_g('新增')}${s_y('标签')}：`) : '';
                            detail.datas[i].forEach((item) => {
                                temp = temp + s_g(item) + '，';
                            });
                            detail.datas[i].length > 0 ? (temp = `${temp.slice(0, -1)}；`) : '';
                            break;
                        case '父级储存区': {
                            let res = await this.$api('getStorageList', { parent_id: -1 });
                            res.storage_list.forEach((item) => {
                                if (item.id == detail.datas[i]) {
                                    temp = `${temp}${s_y(field)}的值为${s_g(item.name)}；`;
                                }
                            });
                            break;
                        }
                        case '所属分类':
                        case '父级分类': {
                            let res = await this.$api('getCategoryList', { parent_id: -1 });
                            res.category_list.forEach((item) => {
                                if (item.id == detail.datas[i]) {
                                    temp = `${temp}${s_y(field)}的值为${s_g(item.name)}；`;
                                }
                            });
                            break;
                        }
                        case '规格':
                        case '材质':
                        case '备注':
                        case '分类前缀':
                            detail.datas[i] = detail.datas[i] == '' ? s_b('无') : detail.datas[i];
                            temp = `${temp}${s_y(field)}的值为${s_g(detail.datas[i])}；`;
                            break;
                        case '后缀':
                            detail.datas[i] = this._10to32(detail.datas[i]);
                            temp = `${temp}${s_y(field)}的值为${s_g(detail.datas[i])}；`;
                            break;
                        default:
                            temp = `${temp}${s_y(field)}的值为${s_g(detail.datas[i])}；`;
                            break;
                    }
                }
                return temp;
            }
            // 操作为编辑
            if (operation == 'edit') {
                let temp = '修改了数据。</br>';
                for (let i = 0; i < detail.change_fields.length; i++) {
                    let field = this.field_name[this.data_table][detail.change_fields[i]];
                    switch (field) {
                        case undefined:
                            break;
                        case '图片': {
                            detail.old_datas[i] = JSON.parse(detail.old_datas[i]);
                            detail.new_datas[i] = JSON.parse(detail.new_datas[i]);
                            // 删除的图片数组
                            let del = [];
                            // 新增的图片数组
                            let ins = [];
                            detail.old_datas[i].forEach((item) => {
                                detail.new_datas[i].indexOf(item) == -1 ? del.push(item) : '';
                            });
                            detail.new_datas[i].forEach((item) => {
                                detail.old_datas[i].indexOf(item) == -1 ? ins.push(item) : '';
                            });
                            // 如果存在则拼接，格式：删除图片：图片，图片，图片；
                            del.length > 0 ? (temp = `${temp}${s_r('删除')}${s_y('图片')}：`) : '';
                            del.forEach((item) => {
                                temp = temp + `<a type="img" src="${item}">图片</a>，`;
                            });
                            del.length > 0 ? (temp = `${temp.slice(0, -1)}；`) : '';
                            // 如果存在则拼接，格式：新增图片：图片，图片，图片；
                            ins.length > 0 ? (temp = `${temp}${s_g('新增')}${s_y('图片')}：`) : '';
                            ins.forEach((item) => {
                                temp = temp + `<a type="img" src="${item}">图片</a>，`;
                            });
                            ins.length > 0 ? (temp = `${temp.slice(0, -1)}；`) : '';
                            break;
                        }
                        case '标签': {
                            // 删除的标签
                            let del = [];
                            // 新增的标签
                            let ins = [];
                            detail.old_datas[i].forEach((item) => {
                                detail.new_datas[i].indexOf(item) == -1 ? del.push(item) : '';
                            });
                            detail.new_datas[i].forEach((item) => {
                                detail.old_datas[i].indexOf(item) == -1 ? ins.push(item) : '';
                            });
                            // 如果存在则拼接，格式：删除标签：xx，xxxx；
                            del.length > 0 ? (temp = `${temp}${s_r('删除')}${s_y('标签')}：`) : '';
                            del.forEach((item) => {
                                temp = temp + s_r(item) + '，';
                            });
                            del.length > 0 ? (temp = `${temp.slice(0, -1)}；`) : '';
                            // 如果存在则拼接，格式：新增标签：xx，xxxx；
                            ins.length > 0 ? (temp = `${temp}${s_g('新增')}${s_y('标签')}：`) : '';
                            ins.forEach((item) => {
                                temp = temp + s_g(item) + '，';
                            });
                            ins.length > 0 ? (temp = `${temp.slice(0, -1)}；`) : '';
                            break;
                        }
                        case '父级储存区': {
                            let old_parent_name = '储存区引用错误！';
                            let new_parent_name = '储存区引用错误！';
                            let res = await this.$api('getStorageList', { parent_id: -1 });
                            res.storage_list.forEach((item) => {
                                if (item.id == detail.old_datas[i]) {
                                    old_parent_name = item.name;
                                }
                                if (item.id == detail.new_datas[i]) {
                                    new_parent_name = item.name;
                                }
                            });
                            temp = `${temp}${s_y(field)}由${s_r(old_parent_name)}修改为${s_g(new_parent_name)}；`;
                            break;
                        }
                        case '所属分类':
                        case '父级分类': {
                            let old_parent_name = '分类引用错误！';
                            let new_parent_name = '分类引用错误！';
                            let res = await this.$api('getCategoryList', { parent_id: -1 });
                            res.category_list.forEach((item) => {
                                if (item.id == detail.old_datas[i]) {
                                    old_parent_name = item.name;
                                }
                                if (item.id == detail.new_datas[i]) {
                                    new_parent_name = item.name;
                                }
                            });
                            temp = `${temp}${s_y(field)}由${s_r(old_parent_name)}修改为${s_g(new_parent_name)}；`;
                            break;
                        }
                        case '规格':
                        case '材质':
                        case '备注':
                        case '分类前缀':
                            detail.old_datas[i] = detail.old_datas[i] == '' ? s_b('无') : detail.old_datas[i];
                            detail.new_datas[i] = detail.new_datas[i] == '' ? s_b('无') : detail.new_datas[i];
                            temp = `${temp}${s_y(field)}由${s_r(detail.old_datas[i])}修改为${s_g(detail.new_datas[i])}；`;
                            break;
                        case '后缀':
                            detail.new_datas[i] = this._10to32(detail.new_datas[i]);
                            detail.old_datas[i] = this._10to32(detail.old_datas[i]);
                            temp = `${temp}${s_y(field)}由${s_r(detail.old_datas[i])}修改为${s_g(detail.new_datas[i])}；`;
                            break;
                        default:
                            temp = `${temp}${s_y(field)}由${s_r(detail.old_datas[i])}修改为${s_g(detail.new_datas[i])}；`;
                            break;
                    }
                }
                return temp;
            }

            // 操作为删除
            if (operation == 'delete') {
                return '删除了数据。';
            }
        },
        // 对点击描述的事件进行处理
        clickDetail(event) {
            switch (event.target.getAttribute('type')) {
                case 'img':
                    let src = event.target.getAttribute('src');
                    this.image_modal = true;
                    this.image_src = `${this.$store.state.backend_url}/image/${src}`;
                    break;
            }
        },
        _10to32(num10) {
            num10 = parseInt(num10);
            if (num10 < 0 || num10 > 1048575) {
                throw new Error('用于转换的10进制数太小或太大，仅限0~1048575，而你传入了：' + num10);
            }
            let map = '0123456789ABCEFGHJKLMNPQRSTUVWXY';
            let str32 = '';
            while (true) {
                str32 = map[num10 % 32] + str32;
                num10 = parseInt(num10 / 32);
                if (num10 == 0) {
                    return ('000' + str32).slice(-4);
                }
            }
        },
    },
};
</script>

<style></style>
